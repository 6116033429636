import * as React from 'react';

import { graphql } from 'gatsby';

import Container from '../components/Container';
import Layout from '../components/Layout';
import Questions from '../components/Questions';

const FaqPage = ({ data }) => {
  const { title, qAndA } = data.contentfulFaq;
  return (
    <Layout pagetitle="FAQ" title={title}>
      <Container>
        <Questions questions={qAndA} />
      </Container>
    </Layout>
  );
};

export default FaqPage;

export const pageQuery = graphql`
  query FaqQuery {
    contentfulFaq {
      title
      qAndA {
        id
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
