import cx from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import * as styles from './QuestionAnswer.module.scss';

import { RightChevron } from '../../icons';
import RichText from '../RichText';

export const QuestionAnswer = ({ question, answer }) => {
  const [isActive, setActive] = useState(false);
  return (
    <div onClick={() => setActive(!isActive)}>
      <div className={cx(styles.question, { [styles.active]: isActive })}>
        <RightChevron />
        {question}
      </div>
      <div className={cx(styles.answer, { [styles.active]: isActive })}>
        <RichText
          dangerouslySetInnerHTML={{ __html: answer.childMarkdownRemark.html }}
        />
      </div>
    </div>
  );
};
