import * as React from 'react';
import * as styles from './Questions.module.scss';

import QuestionAnswer from '../QuestionAnswer';

export const Questions = ({ questions }) => (
  <div className={styles.root}>
    {questions.map(q => (
      <QuestionAnswer answer={q.answer} key={q.id} question={q.question} />
    ))}
  </div>
);
